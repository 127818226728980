@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{

  button{
    @apply border-black rounded-xl px-5 py-1 ;
  }
}
