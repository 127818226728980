/* Slider Styles */
.slider {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Display the images and pagination dots in a column */
  height: 65vh;
  position: relative;
}

.slider img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin-bottom: 20px; /* Add some space between images and pagination */
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}




/* Slide Form Styles */
.slide-form {
  position: absolute;
  top: 30%;
  left: 10%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 35%;
  height: 65vh;
  border-radius: 5px;
  color: #fff;
}

.slide-form h2 {
  margin-top: 30px;
}

.slide-form input {
  margin-top: 20px;
}

.slide-form .submit {
  background-color: #178cd5fc;
}

@media screen and (max-width: 768px) {
  .slider {
    height: 65vh;
  }
  .slider img {
    height: 65vh;
  }
  .slide-form {
    left: 5%;
    top: 32%;
    width: 90%;
    height: 55vh;
  }
}
/* Next Button Styles */
.slider .next {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  z-index: 1; /* Ensure the buttons appear above other elements */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
}

.slider .next:hover {
  color: #ff0000; /* Change color on hover */
}

/* Previous Button Styles */
.slider .prev {
  position: absolute;
  top: 50%;
  left: 5%; /* Position on the left side */
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  z-index: 1; /* Ensure the buttons appear above other elements */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
}

.slider .prev:hover {
  color: #ff0000; /* Change color on hover */
}
/* Add styles for the RxDotFilled icon */
.slider .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.slider .pagination-dot {
  cursor: pointer;
  transition: color 0.3s ease;
}

.slider .pagination-dot .rx-dot {
  font-size: 24px;
}

/* Active dot styles */
.slider .pagination-dot.active .rx-dot {
  color: #007BFF; /* Set the color for the active state */
}

/* Hover styles for dots */
.slider .pagination-dot:hover .rx-dot {
  color: #ff0000; /* Change color on hover */
}
